import React, { Component, useState } from 'react'

import { Button, Checkbox, Message, Transition, Loader, Segment, Header, Icon } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import Burry from 'burry'
import { parse as parseQueryString } from 'querystring'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { CryphoAPI } from '../../crypho.core/api'
import { authenticate } from '../../crypho.core/auth'
import * as XMPP from '../../crypho.core/xmpp'
import store from '../../crypho.core/store'
import { loadStateFromCache } from '../../store/storage'
import {
  setIdentityEmail,
  setTFASecret,
  setPhone,
  fetchScryptSaltFromServer,
} from '../../crypho.core/store/modules/identity'
import { saveSession } from '../../session'
import { saveIdentity } from '../../store/identity'
import { errorLabel } from '../../UI/forms'
import { GoogleReCaptchaContext, GoogleRecaptchaProvider } from '../../components/google-recaptcha'

class LoginScene extends Component {
  constructor(properties) {
    super(properties)
    this.state = {
      username: '',
      password: '',
      tfaToken: '',
      trusted: true,
      processing: false,
      showPassword: false,
      statusMessage: '',
      statusMessageType: null,
    }
  }

  componentDidMount() {
    this.authTokenStore = new Burry.Store('auth.token', 1209600)
    const email = this.authTokenStore.keys()
    if (email.length > 0 && this.authTokenStore.get(email[0])) {
      this.setState({ username: email[0], rememberToken: this.authTokenStore.get(email[0]) })
    }

    const params = parseQueryString(this.props.location.search.slice(1))
    if (params.msg) {
      // eslint-disable-next-line unicorn/prevent-abbreviations
      const { msg } = params
      switch (msg) {
        case '4':
          this.setStatusMessage('The email has been registered to your account.')
          break
        case '5':
          this.setStatusMessage(
            'There was an error registering this email to your account. The link has expired or the email is already registered.',
          )
          break
        default:
          break
      }
    }
  }

  setStatusMessage = (statusMessage, statusMessageType = 'info') => {
    this.setState({ statusMessage: '', statusMessageType })
    this.setState({ statusMessage, statusMessageType })
  }

  sendSecurityToken = async (push) => {
    const { apiUrl } = this.props
    const api = new CryphoAPI(apiUrl)
    const { username } = this.state
    this.setState({ tfaToken: '' })

    try {
      const recaptcha_token = await this.context.executeRecaptcha('LOGIN')
      if (!recaptcha_token) {
        this.setStatusMessage(`Please confirm 'I'm not a robot'`, 'warning')
        return
      }
      await api.post('/auth/sendtoken', { email: username, push, recaptcha_token, platform: 'web' })
      this.setStatusMessage('We have sent you a security code. It is valid for 60 seconds.')
    } catch (error) {
      if (error.error == 'inactive-account') {
        this.setStatusMessage('Account suspended. Contact support for further details.', 'error')
      } else {
        this.setStatusMessage('We could not send you a security code.', 'warning')
      }
    }
  }

  onLogin = async () => {
    const { history, saveIdentity } = this.props
    const { username, password, tfaToken, trusted, rememberToken } = this.state

    await this.props.setIdentityEmail(this.state.username)
    const token = rememberToken || tfaToken
    this.setState({ processing: true })
    try {
      const recaptcha_token = await this.context.executeRecaptcha('LOGIN')
      if (!recaptcha_token) {
        this.setStatusMessage(`Please confirm 'I'm not a robot'`, 'warning')
        this.setState({ processing: false })
        return
      }
      await this.props.fetchScryptSaltFromServer(this.state.username)
      const { rememberToken } = await authenticate(
        username,
        password,
        token,
        this.props.identity.scryptSalt,
        recaptcha_token,
        'web',
        trusted,
      )
      if (trusted) {
        this.authTokenStore.set(username, rememberToken)
      }
      saveSession()
      await store.dispatch(XMPP.setXmppPresence(true))
      await store.dispatch(loadStateFromCache())
      await XMPP.reloadCredentials()
    } catch (error) {
      await store.dispatch(XMPP.setXmppPresence(false))
      this.setState({ processing: false })
      switch (error.error) {
        case 'invalid-2fa-token':
          this.setStatusMessage('Your security code is incorrect.', 'error')
          break
        case 'remember-token-expired':
          this.setStatusMessage('The authentication token for this browser has expired.', 'error')
          break
        case 'invalid-auth-hash':
          this.setStatusMessage('Your passphrase is incorrect.', 'error')
          break
        case 'inactive-account':
          this.setStatusMessage('Account suspended. Contact support for further details.', 'error')
          break
        case 'rate-limit-exceeded':
          this.setStatusMessage('Too many authentication attempts. Please try again after 5 seconds.', 'error')
          break
        default:
          this.setStatusMessage('Could not log in.', 'error')
          break
      }
      this.authTokenStore.flush()
      this.setState({ rememberToken: '' })
      return
    }

    const twoFactorData = await XMPP.crypho.getTwoFactorData()
    this.props.setTFASecret(twoFactorData.secret)
    this.props.setPhone(twoFactorData.local, twoFactorData.country)
    saveIdentity()
    this.setState({ processing: false })
    history.push('/splash')
  }

  untrustBrowser = () => {
    this.authTokenStore.flush()
    this.setState({ username: '', rememberToken: '' })
  }

  render() {
    const { statusMessage, statusMessageType, trusted, username, rememberToken, processing } = this.state
    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Log in</Header>
            </div>
            <Loader disabled={!processing} />
            <Form onValidSubmit={this.onLogin}>
              <Transition animation="scale" duration={statusMessage ? 1000 : 0} visible={!!statusMessage}>
                <Message className={statusMessageType} content={statusMessage || ''} />
              </Transition>
              <a onClick={this.untrustBrowser} className="changeUser" hidden={!rememberToken}>
                {`Not ${username}?`}
              </a>
              <Form.Input
                name="email"
                type="email"
                autoComplete="username"
                placeholder="Email"
                tabIndex={1}
                required
                validations="isEmail"
                value={this.state.username}
                onChange={(event_) => this.setState({ username: event_.target.value })}
                readOnly={!!rememberToken}
                errorLabel={errorLabel}
                validationErrors={{
                  isEmail: 'This is not a valid email',
                  isDefaultRequiredValue: 'Email is required',
                }}
              />

              <Form.Input
                action={
                  <Icon
                    disabled
                    name="eye"
                    title={this.state.showPassword ? 'Hide passphrase' : 'Show passphrase'}
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  />
                }
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                placeholder="Passphrase"
                tabIndex={2}
                required
                value={this.state.password}
                onChange={(event_) => this.setState({ password: event_.target.value })}
                errorLabel={errorLabel}
                validationErrors={{
                  isDefaultRequiredValue: 'Your passphrase is required',
                }}
              />
              <Link className="greyTextLink" name="recover" to="/recover">
                Forgot your passphrase?
              </Link>
              <div id="g-recaptcha"></div>
              <Segment basic hidden={!!rememberToken}>
                <p>
                  Enter 6 digit security code. Receive the code on your mobile phone, either through the Crypho app or
                  via text message.
                </p>

                <Form.Input
                  name="token"
                  type="number"
                  placeholder="Security code"
                  tabIndex={3}
                  width={6}
                  className="inlineInput"
                  value={this.state.tfaToken}
                  required={!rememberToken}
                  errorLabel={errorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: 'The security code is required',
                  }}
                  onChange={(event_) => this.setState({ tfaToken: event_.target.value })}
                />
                <Button
                  type="button"
                  className="securityTokenButton"
                  formNoValidate="formNoValidate"
                  onClick={() => this.sendSecurityToken(false)}
                  id="send-sms"
                  disabled={!this.props.captchaReady}
                >
                  Send SMS
                </Button>
                <Button
                  type="button"
                  className="securityTokenButton"
                  formNoValidate="formNoValidate"
                  onClick={() => this.sendSecurityToken(true)}
                  id="send-push"
                  disabled={!this.props.captchaReady}
                >
                  Send to app
                </Button>
                <Form.Field>
                  <Checkbox
                    name="unstrusted"
                    label="This is a shared computer. Always ask for security codes."
                    checked={!trusted}
                    onChange={() => {
                      this.setState({ trusted: !trusted })
                    }}
                  />
                </Form.Field>
              </Segment>
              <Button
                name="login"
                tabIndex={4}
                className="primaryButton"
                fluid
                type="submit"
                disabled={processing || !this.props.captchaReady}
              >
                Log in
              </Button>
              {/* <Link className="blueTextLink top20" name="signup" to="/signup">
                Don&apos;t have an account? Sign Up
              </Link> */}
            </Form>
          </div>

          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

LoginScene.propTypes = {
  apiUrl: PropTypes.string,
  fetchScryptSaltFromServer: PropTypes.func,
  history: PropTypes.shape(),
  identity: PropTypes.shape(),
  location: PropTypes.shape(),
  saveIdentity: PropTypes.func,
  setIdentityEmail: PropTypes.func,
  setPhone: PropTypes.func,
  setTFASecret: PropTypes.func,
  captchaReady: PropTypes.bool,
}

LoginScene.contextType = GoogleReCaptchaContext

const mapStateToProperties = (state) => ({
  identity: state.identity,
  apiUrl: state.config.apiUrl,
  recaptchaWebSiteKey: state.config.recaptchaWebSiteKey,
  recaptchaEnabled: state.config.recaptchaEnabled,
})

const mapDispatchToProperties = {
  setIdentityEmail,
  setTFASecret,
  setPhone,
  fetchScryptSaltFromServer,
  saveIdentity,
}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

const loginSceneWithRecaptchaProvider = (props) => {
  const { recaptchaEnabled, recaptchaWebSiteKey } = props
  const [ready, setReady] = useState(false)
  const readyHandler = () => {
    setReady(true)
  }
  return (
    <GoogleRecaptchaProvider
      reCaptchaKey={recaptchaWebSiteKey}
      useEnterprise={true}
      disabled={!recaptchaEnabled}
      readyCallBack={readyHandler}
      container={{ element: 'g-recaptcha', parameters: { size: 'normal', action: 'LOGIN' } }}
    >
      <LoginScene captchaReady={ready} {...props} />
    </GoogleRecaptchaProvider>
  )
}
loginSceneWithRecaptchaProvider.propTypes = {
  recaptchaWebSiteKey: PropTypes.string,
  recaptchaEnabled: PropTypes.bool,
}

export default connector(loginSceneWithRecaptchaProvider)
