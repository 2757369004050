import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import store from './crypho.core/store'
import { setAwayStatus } from './crypho.core/store/modules/app'
import { loadStateFromCache } from './store/storage'
import { client, crypho } from './crypho.core/xmpp'
import { loadSession } from './session'
import { fetchIdentity } from './store/identity'
import * as XMPP from './crypho.core/xmpp'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import showLatestChanges from './UI/widgets/latestchanges'
import ContactsScene from './scenes/space/contacts'
import GroupsScene from './scenes/space/groups'
import CompanyScene from './scenes/company'
import SupportScene from './scenes/support'
import Changelog from './scenes/changelog'
import InvitationsScene from './scenes/invitations'
import NotFoundScene from './scenes/notfound'
import LoginScene from './scenes/login'
import RequestPassphraseResetScene from './scenes/recovery/requestpassphrasereset'
import ResetPassphraseScene from './scenes/recovery/resetpassphrase'
import RecoverAccountScene from './scenes/recovery/recoveraccount'
import SignupScene from './scenes/registration/signup'
import RegisterScene from './scenes/registration/register'
import Register2FAScene from './scenes/registration/register2fa'
import SignedUpScene from './scenes/registration/signedup'
import DeletedAccount from './scenes/registration/deleted'

import AdminScene from './scenes/admin'
import MyProfile from './scenes/settings/myprofile'
import Settings from './scenes/settings/settings'
import Device from './scenes/settings/devices'
import ChangePassphrase from './scenes/settings/changepassphrase'
import ShowRecoveryKey from './scenes/settings/recoverykey'
import Verification from './scenes/settings/verification'

import SplashScene from './scenes/splash'
import MobileRedirect from './UI/mobileredirect'
import { WebCallHandler, WebCallManager } from './webrtc'
import CallWidget from './UI/webrtc'
import { cryphoPlugin } from 'crypho.core/xmpp/client'
import { deleteSession } from './session'

const callManager = new WebCallManager()
const showMobileRedirect = /iphone|ipad|ipod|android/i.test(navigator.userAgent)

// Check if in an electron environment and import desktop specific stuff
let DesktopComponent = null
if (process.env.REACT_APP_DESKTOP || (window && window.process && window.process.type)) {
  DesktopComponent = require('./desktop.jsx').connectedBridge
}

const inactivityTimeout = 3 * 60000

class App extends Component {
  idleAwayTimeout = null

  async componentDidMount() {
    const { history } = this.props
    // Show changelog if we are not running in development
    if (!develMode) {
      client.on('ready', showLatestChanges)
    }
    client.on('ready', () => {
      const state = store.getState()
      const invitationsToAccount = state.account.invitationsToAccount
      if (invitationsToAccount) {
        const { title } = invitationsToAccount
        NotificationManager.create({
          type: 'info',
          message: `You have been invited to join the account of ${title}.`,
          timeOut: 1000 * 60 * 60,
          onClick: () => {
            history.push('/company')
          },
        })
      }
    })

    crypho.on('service-message', (message) => {
      NotificationManager.info(message)
    })

    cryphoPlugin.on('account-suspended', async () => {
      const { history } = this.props
      await store.dispatch(XMPP.setXmppPresence(false)).catch(() => {})
      await XMPP.stop()
      await deleteSession()
      history.push('/')
    })

    crypho.on('clock-sync-offset', (offset) => {
      // If clock is out of sync with the server by more than 3 min alert.
      if (Math.abs(offset) > 180000) {
        NotificationManager.error(
          `Your computer's clock seems to be out of sync. Please quit Crypho, synchronize your clock and start Crypho again.`,
        )
      }
    })

    const { fetchIdentity } = this.props
    try {
      loadSession()
      await fetchIdentity()
      await store.dispatch(loadStateFromCache())
    } catch (error) {
      // This will fail if the user has no auth session.
      return
    }

    try {
      await store.dispatch(XMPP.setXmppPresence(true))
      if (history.location.pathname.startsWith('/register')) return
      await XMPP.start()
      await XMPP.onceOnline()
    } catch (error) {
      Sentry.captureException(error)
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  componentDidUpdate(previousProperties) {
    const { isXmppOnline, inactivityAwayTimeout } = this.props

    if (isXmppOnline && !previousProperties.isXmppOnline && inactivityAwayTimeout) {
      this.idleAwayTimeout = setTimeout(this.onInactive, inactivityTimeout)
    }

    if (!isXmppOnline && previousProperties.isXmppOnline) {
      clearTimeout(this.idleAwayTimeout)
    }
  }

  onActive = () => {
    store.dispatch(setAwayStatus(false))
    // We do not wait for sendAway to finish to keep the handler fast
    // eslint-disable-next-line no-console
    store.dispatch(XMPP.setXmppPresence(true)).catch(console.error)

    // Since we update unread while away, when we are no longer idle
    // we need to mark the messages on the current space as read.
    const { pathname } = window.location
    if (pathname.indexOf('contacts') || pathname.indexOf('groups')) {
      const [spaceId] = pathname.split('/').slice(-1)
      // eslint-disable-next-line no-console
      crypho.ping(spaceId).catch(console.error)
    }
  }

  onInactive = () => {
    if (callManager.state === 'idle') {
      // We do not wait for sendAway to finish to keep the timeout handler fast
      // eslint-disable-next-line no-console
      store.dispatch(setAwayStatus(true))
      store.dispatch(XMPP.setXmppPresence(true, 'away')).catch(console.error)
    }
    if (this.idleAwayTimeout) {
      clearTimeout(this.idleAwayTimeout)
    }
    this.idleAwayTimeout = null
  }

  onActivity = () => {
    const { away, isXmppOnline, inactivityAwayTimeout } = this.props
    if (!isXmppOnline) return
    if (away) {
      this.onActive()
    }
    if (this.idleAwayTimeout) {
      clearTimeout(this.idleAwayTimeout)
    }
    if (inactivityAwayTimeout) {
      this.idleAwayTimeout = setTimeout(this.onInactive, inactivityAwayTimeout)
    }
  }

  render() {
    const { away } = this.props
    // The electron file:// paths do not fair well with react-router.
    // In order to handle this we redirect the first load in order to be able
    // to start on the desktop
    if (window.location.pathname.includes('index.html')) {
      return <Redirect to="/" />
    }

    return (
      <WebCallHandler manager={callManager}>
        <div
          id="scene-container"
          className={away ? 'away' : ''}
          onKeyPress={this.onActivity}
          onMouseMove={this.onActivity}
        >
          {showMobileRedirect ? <MobileRedirect /> : null}
          <NotificationContainer />
          <CallWidget />
          <Switch>
            <Route path="/login" exact={true} component={LoginScene} />
            <Route path="/recover" exact={true} component={RecoverAccountScene} />
            <Route path="/reset" exact={true} component={RequestPassphraseResetScene} />
            <Route path="/reset-passphrase/:token" exact={true} component={ResetPassphraseScene} />

            <Route path="/signup" exact={true} component={SignupScene} />
            <Route path="/signup/:openInvitationToken" exact={true} component={SignupScene} />

            <Route path="/signedup" exact={true} component={SignedUpScene} />
            <Route path="/deleted" exact={true} component={DeletedAccount} />
            <Route path="/register/:token" exact={true} component={RegisterScene} />
            <Route path="/register/twofactor/:token" exact={true} component={Register2FAScene} />

            <Route path="/splash" exact={true} component={SplashScene} />
            <AuthenticatedRoute path="/" exact={true} component={ContactsScene} />
            <AuthenticatedRoute path="/contacts" exact={true} component={ContactsScene} />
            <AuthenticatedRoute path="/contacts/:spaceId" exact={true} component={ContactsScene} />
            <AuthenticatedRoute path="/groups" exact={true} component={GroupsScene} />
            <AuthenticatedRoute path="/groups/:spaceId" exact={true} component={GroupsScene} />
            <AuthenticatedRoute path="/company" exact={true} component={CompanyScene} />
            <AuthenticatedRoute path="/invitations" exact={true} component={InvitationsScene} />
            <AuthenticatedRoute path="/support" exact={true} component={SupportScene} />
            <AuthenticatedRoute path="/changelog" exact={true} component={Changelog} />
            <AuthenticatedRoute path="/admin" component={AdminScene} />

            <AuthenticatedRoute path="/profile" exact={true} component={MyProfile} />
            <AuthenticatedRoute path="/devices" exact={true} component={Device} />
            <AuthenticatedRoute path="/passphrase" exact={true} component={ChangePassphrase} />
            <AuthenticatedRoute path="/recovery-key" exact={true} component={ShowRecoveryKey} />
            <AuthenticatedRoute path="/verification" exact={true} component={Verification} />
            <AuthenticatedRoute path="/user-verification/redirect" exact={true} component={Verification} />
            <AuthenticatedRoute path="/changelog" exact={true} component={Changelog} />
            <AuthenticatedRoute path="/settings" exact={true} component={Settings} />
            <Route component={NotFoundScene} />
          </Switch>
          {DesktopComponent ? <DesktopComponent /> : null}
          {away ? (
            <p id="away-container">
              You have been idle. <br />
              We set your status to &quot;away&quot;
            </p>
          ) : null}
        </div>
      </WebCallHandler>
    )
  }
}

App.propTypes = {
  component: PropTypes.shape(),
  location: PropTypes.any,
  away: PropTypes.bool,
  fetchIdentity: PropTypes.func.isRequired,
  inactivityAwayTimeout: PropTypes.number.isRequired,
  isXmppOnline: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
}

const mapStateToProperties = (state) => ({
  identity: state.identity,
  away: state.app.away,
  inactivityAwayTimeout: state.settings.inactivityAwayTimeout,
  isXmppOnline: state.xmpp.status === 'online',
})

const mapDispatchToProperties = {
  fetchIdentity,
}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(App)

const develMode = process.env.NODE_ENV === 'development' || (window._RUNTIME || {}).CI
