import React, { Component } from 'react'
import { List, Button, Icon } from 'semantic-ui-react'

const storeLink = /android/i.test(navigator.userAgent)
  ? 'https://play.google.com/store/apps/details?id=com.crypho.app'
  : 'https://appstore.com/crypho'

const getRegisterTokenFromPath = (path) => {
  const match = path.match(/\/register\/(?!twofactor)([^/]+)/)
  return match ? match[1] : null
}
class MobileRedirect extends Component {
  state = { visible: true }

  redirect = () => {
    let registerUrl = ''
    const registerToken = getRegisterTokenFromPath(window.location.pathname)
    if (registerToken) {
      registerUrl = '/register/' + registerToken
    }
    window.location = 'crypho://app' + registerUrl
    setTimeout(function () {
      window.location = storeLink
    }, 250)
  }

  close = () => {
    this.setState({ visible: false })
  }

  render() {
    const { visible } = this.state
    if (!visible) return null
    if (
      window.location.pathname.indexOf('signup') > 0 ||
      window.location.pathname.indexOf('signedup') > 0 ||
      window.location.pathname.indexOf('passphrase') > 0
    )
      return null
    return [
      // eslint-disable-next-line react/jsx-key
      <List divided verticalAlign="middle" className="redirect">
        <List.Item>
          <Icon disabled name="close" onClick={this.close} />
          <div className="topLogo" title="CRYPHO" />
          <List.Content>Use the Crypho app</List.Content>
          <Button primary onClick={this.redirect}>
            Open
          </Button>
        </List.Item>
      </List>,
    ]
  }
}

export default MobileRedirect
