import React, { useCallback, useEffect, useState } from 'react'
import { Card, Header, Checkbox, Icon, Select, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'

import { getAdminConfig, setAdminConfig } from '../api'
import classes from './UserManagement.module.css'

const UserManagement = ({ apiUrl }) => {
  const [editing, setEditing] = useState(false)
  const [enable, setEnable] = useState(false)
  const [inactiveDays, setInactiveDays] = useState(null)
  const [loading, setLoading] = useState(null)

  const getConfig = useCallback(async () => {
    setLoading(true)
    try {
      const adminConfig = await getAdminConfig(apiUrl)
      setEnable(adminConfig.account_deactivation_job_enabled === 'true')
      setInactiveDays(+adminConfig.account_inactivity_threshold_days)
    } catch (error) {
      NotificationManager.error('Failed to read config', 'Error')
    }
    setLoading(false)
  })

  useEffect(() => {
    ;(async () => {
      await getConfig()
    })()
  }, [apiUrl])

  const handleEnable = () => {
    setEnable((enable) => !enable)
  }

  const handleSelectChange = (ev, option) => {
    setInactiveDays(option.value)
  }

  const handleEdit = () => {
    setEditing(true)
  }

  const handleEditCancel = () => {
    setEditing(false)
  }

  const handleSave = async () => {
    if (enable && !inactiveDays) {
      return
    }
    const adminConfig = {
      account_deactivation_job_enabled: enable ? 'true' : 'false',
      account_inactivity_threshold_days: '' + inactiveDays,
    }
    setLoading(true)
    try {
      await setAdminConfig(apiUrl, adminConfig)
      setEditing(false)
    } catch (error) {
      NotificationManager.error('Failed to set config', 'Error')
    }
    await getConfig()
  }

  return (
    <>
      <div className={classes.card}>
        {loading && (
          <div className={classes.loader}>
            <div className="ui active large inverted text loader">Loading</div>
          </div>
        )}
        <Card.Group itemsPerRow={1}>
          <Card>
            <div className={classes.cardContent}>
              <div className={classes.cardTitle}>
                <Header as="h3">Users deactivate schedule</Header>
                <div className={classes.editIcon} onClick={handleEdit}>
                  <Icon className="edit" title="Edit" />
                </div>
              </div>
              <p>
                By selecting and enabling this setting free users will be deactivated automatically after selected
                duration of inactivity. The job is scheduled to execute once a day at midnight.
              </p>
              <Header as="h3">user inactive time</Header>
              <div className={classes.fields}>
                <Select
                  options={[
                    { text: '3 Months', value: 3 * 30 },
                    { text: '4 Months', value: 4 * 30 },
                    { text: '5 Months', value: 5 * 30 },
                    { text: '6 Months', value: 6 * 30 },
                    { text: '7 Months', value: 7 * 30 },
                    { text: '8 Months', value: 8 * 30 },
                    { text: '9 Months', value: 9 * 30 },
                    { text: '10 Months', value: 10 * 30 },
                    { text: '11 Months', value: 11 * 30 },
                    { text: '1 Year', value: 12 * 30 },
                  ]}
                  value={inactiveDays}
                  disabled={!editing}
                  onChange={handleSelectChange}
                />
                <Checkbox
                  toggle
                  checked={enable}
                  onChange={handleEnable}
                  disabled={!editing}
                  name="enable"
                  className={classes.checkbox}
                ></Checkbox>
              </div>
              {editing && (
                <div className={classes.actions}>
                  <Button name="save" className="settingsButton" type="button" onClick={handleSave}>
                    Save
                  </Button>
                  <Button name="cancel" className="settingsButton" type="button" onClick={handleEditCancel}>
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </Card>
        </Card.Group>
      </div>
    </>
  )
}

UserManagement.propTypes = {
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)

export default connector(UserManagement)
