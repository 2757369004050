import React, { Component } from 'react'
import { Button, Grid, Header, Popup, Card } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import SpaceList from '../spacelist'
import InvitationList from '../../invitations/invitationlist'

import { getContactSpaces } from '../../../crypho.core/store/modules/space/selectors'
import SpaceScene from '../SpaceScene'
import InviteContactForm from '../../invitations/invitecontact'
import { ColorFilter } from '../../../UI/widgets/color'
import SpaceSearch from '../../../UI/widgets/spacesearch'
import Topbar from '../../../UI/Topbar'

class ContactsScene extends Component {
  state = {
    filter: -1,
  }

  constructor(properties) {
    super(properties)
    this.inviteContactFormReference = React.createRef()
  }

  componentDidUpdate() {
    // We want to replace the history url if we are going to switch to the latest space
    const { spaceId, history } = this.props
    let { spaces } = this.props
    const { filter } = this.state

    if (filter !== -1) spaces = spaces.filter((space) => space.color === filter)

    if (spaces.length > 0 && !spaceId) {
      history.replace(`/contacts/${spaces[0].id}`)
    }
  }

  onFilterUpdate = (color) => {
    const { history } = this.props
    const { filter } = this.state
    if (filter === color) this.setState({ filter: -1 })
    else this.setState({ filter: color })
    history.push('/contacts')
  }

  render() {
    const { spaceId, stateLoaded, account, userId } = this.props
    const isAdmin = account.admins.includes(userId)
    const isFree = ['free', 'beta'].includes(account.plan.name)
    if (!stateLoaded) return null
    let { spaces } = this.props
    const totalCount = spaces.length
    const { filter } = this.state
    if (filter !== -1) {
      spaces = spaces.filter((space) => space.color === filter)
    }
    const count = spaces.length

    return (
      <div className="authenticatedScene">
        <Topbar />
        <div id="contact-scene">
          <div className={spaces.length === 0 ? 'fullWidth' : 'list-container'}>
            <Grid className={spaces.length === 0 ? 'fullWidth spacelistHeader' : 'spacelistHeader'}>
              <Grid.Column width={filter === -1 ? 10 : 16}>
                <div className="helpContainer">
                  <Popup
                    trigger={
                      <Header as="h3">
                        {filter !== -1 ? `${count} of ${totalCount} contacts` : `${count} contacts`}
                      </Header>
                    }
                    wide="very"
                  >
                    Using Crypho, you communicate either directly with your contacts or in groups. To add a contact, you
                    must invite them to be your contact and they must approve your contact request. You can only be made
                    someone’s contact by explicit approval. Only you can see who your contacts are. They are not shared
                    with others.
                  </Popup>
                </div>
                <ColorFilter selected={filter} onUpdate={this.onFilterUpdate} />
              </Grid.Column>

              {filter === -1 ? (
                <Grid.Column width={6} className="buttonContainer">
                  {isAdmin && !isFree && (
                    <>
                      <span>Invite</span>
                      <InviteContactForm ref={this.inviteContactFormReference} />
                      <Button
                        color="blue"
                        circular
                        icon="plus"
                        id="c-add-contact"
                        className="spacelistHeaderButton"
                        title="Invite contact"
                        onClick={this.openContactForm}
                      />
                    </>
                  )}
                </Grid.Column>
              ) : null}
              <Grid.Column width={16} className={spaces.length === 0 ? 'fullWidth spaceSearch' : 'spaceSearch'}>
                <SpaceSearch type="contacts" />
              </Grid.Column>
            </Grid>
            <InvitationList />
            {spaces.length !== 0 ? (
              <SpaceList spaces={spaces} />
            ) : (
              <div className="descriptionContainer">
                {filter === -1 ? (
                  <React.Fragment>
                    <Header>Welcome to Crypho!</Header>
                    <Header.Subheader>Here are few steps to get you started:</Header.Subheader>
                    <Card.Group className="welcome">
                      <Card>
                        <Card.Content>
                          <Card.Header>1. Connect your team</Card.Header>
                          <Card.Meta>Invite contacts</Card.Meta>
                          <Card.Description>
                            Invite them by clicking the <strong>[+]</strong> button at the top.
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra onClick={this.openContactForm} as="a">
                          <span className="blueLink">Invite contacts now</span>
                        </Card.Content>
                      </Card>
                      <Card>
                        <Card.Content>
                          <Card.Header>2. Create groups to collaborate and share</Card.Header>
                          <Card.Meta>Create groups</Card.Meta>
                          <Card.Description>
                            Add existing contacts to groups. Use the <strong>groups</strong> tab at the top.
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra as={NavLink} to="/groups">
                          <span className="blueLink">Go to groups</span>
                        </Card.Content>
                      </Card>
                      <Card>
                        <Card.Content>
                          <Card.Header>3. Use Crypho from your phone</Card.Header>
                          <Card.Meta>Stay synchronized while on the move</Card.Meta>
                          <Card.Description>
                            Download the Crypho app for <strong>iOS</strong> and <strong>Android</strong>.
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <div className="appDownload">
                            <a
                              className="appstore"
                              target="blank"
                              href="https://apps.apple.com/no/app/crypho/id977140293"
                            />
                            <a
                              className="googleplay"
                              target="blank"
                              href="https://play.google.com/store/apps/details?id=com.crypho.app&hl=en"
                            />
                          </div>
                        </Card.Content>
                      </Card>
                    </Card.Group>
                  </React.Fragment>
                ) : (
                  <p className="description">There are no contacts with this color.</p>
                )}
              </div>
            )}
          </div>

          {spaceId && spaces.length > 0 ? (
            <SpaceScene spaceId={spaceId} />
          ) : (
            (spaces.length && <SpaceScene spaceId={spaces[0].id} />) || null
          )}
        </div>
      </div>
    )
  }

  openContactForm = () => this.inviteContactFormReference.current.open()
}

ContactsScene.propTypes = {
  account: PropTypes.object,
  userId: PropTypes.string,
  history: PropTypes.shape(),
  space: PropTypes.shape(),
  spaceId: PropTypes.string,
  spaces: PropTypes.array,
  stateLoaded: PropTypes.bool.isRequired,
}

const mapStateToProperties = (state, ownProperties) => {
  const spaceId = ownProperties.match.params.spaceId
  const { account, identity } = state
  const userId = identity.id
  return {
    account,
    stateLoaded: state.app.stateLoaded,
    spaces: getContactSpaces(state),
    spaceId,
    userId,
  }
}

const mapDispatchToProperties = {}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(ContactsScene)
