import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Header, Button, Transition, Message } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { errorLabel } from '../../UI/forms'
import { GoogleReCaptchaContext, GoogleRecaptchaProvider } from '../../components/google-recaptcha'

const showMobileRedirect = /iphone|ipad|ipod|android/i.test(navigator.userAgent)
class SignupScene extends Component {
  constructor(properties) {
    super(properties)
    this.state = {
      statusMessage: '',
      statusMessageType: null,
      email: '',
      invitorName: '',
      invitorOrgName: '',
    }
  }

  componentDidMount = async () => {
    const { apiUrl, openInvitationToken, history } = this.props
    if (openInvitationToken) {
      try {
        const r = await fetch(`${apiUrl}/open-invitation-token/${openInvitationToken}`)
        const json = await r.json()
        this.setState({ invitorName: json.fullname, invitorOrgName: json.orgName })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    } else {
      history.push('/login')
    }
  }

  onSignup = async () => {
    const { email } = this.state
    const { history, apiUrl, openInvitationToken } = this.props
    if (!email) return

    let recaptcha_token
    try {
      recaptcha_token = await this.context.executeRecaptcha('LOGIN')
    } catch (error) {
      this.setState({
        statusMessage: 'Failed to load Recaptcha',
        statusMessageType: 'error',
      })
      return
    }

    if (!recaptcha_token) {
      this.setState({
        statusMessage: `Please confirm 'I'm not a robot'`,
        statusMessageType: 'error',
      })
      return
    }

    try {
      const response = await fetch(`${apiUrl}/signup`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          email,
          openInvitationToken,
          recaptcha_token,
          platform: 'web',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        if (response.status === 409) {
          this.setState({
            statusMessage: 'You already have an account with that email',
            statusMessageType: 'error',
          })
        } else {
          this.setState({
            statusMessage: ' There was an error signing up',
            statusMessageType: 'error',
          })
        }
        return
      }
    } catch (error) {
      this.setState({
        statusMessage: ' There was an error signing up',
        statusMessageType: 'error',
      })
      return
    }
    history.push('/signedup')
  }
  setStatusMessage = (statusMessage, statusMessageType = 'info') => {
    this.setState({ statusMessage: '', statusMessageType })
    this.setState({ statusMessage, statusMessageType })
  }

  render() {
    const { statusMessage, statusMessageType, invitorName, invitorOrgName } = this.state
    const invitor = invitorOrgName ? `${invitorName}, ${invitorOrgName}` : invitorName ? invitorName : null
    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Sign up</Header>
            </div>
            <Form onValidSubmit={this.onSignup}>
              <Transition animation="scale" duration={statusMessage ? 1000 : 0} visible={!!statusMessage}>
                <Message className={statusMessageType} content={statusMessage || ''} />
              </Transition>
              {showMobileRedirect ? (
                <Message negative compact>
                  We strongly recommend that you use the Crypho app for a better mobile experience.
                </Message>
              ) : null}
              {invitor ? <Message positive>{`${invitor} has invited you to join Crypho.`}</Message> : null}
              <Header as="h4">{'You will receive an email to verify your email address.'}</Header>
              <p className="description">
                {
                  'We will not share your email, spam you or use your email address in any way beyond what is needed to operate Crypho.'
                }
              </p>
              <Form.Input
                name="email"
                type="email"
                placeholder="Email"
                required
                validations="isEmail"
                value={this.state.email}
                onChange={(event_) => this.setState({ email: event_.target.value })}
                errorLabel={errorLabel}
                validationErrors={{
                  isEmail: 'This is not a valid email',
                  isDefaultRequiredValue: 'Email is required',
                }}
              />
              <div id="g-recaptcha"></div>
              <Button
                name="sendVerification"
                className="primaryButton"
                fluid
                type="submit"
                disabled={!this.props.captchaReady}
              >
                Send verification email
              </Button>
              <Link className="blueTextLink top20" name="login" to="/login">
                Already have an account? Log in
              </Link>
            </Form>
          </div>
          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

SignupScene.contextType = GoogleReCaptchaContext

SignupScene.propTypes = {
  history: PropTypes.object.isRequired,
  apiUrl: PropTypes.string.isRequired,
  openInvitationToken: PropTypes.string,
  captchaReady: PropTypes.bool,
}

const mapStateToProperties = (state, ownProperties) => ({
  apiUrl: state.config.apiUrl,
  openInvitationToken: ownProperties.match.params.openInvitationToken,
  recaptchaWebSiteKey: state.config.recaptchaWebSiteKey,
  recaptchaEnabled: state.config.recaptchaEnabled,
})

const connector = (container) => compose(withRouter, connect(mapStateToProperties))(container)
const signupSceneWithRecaptchaProvider = (props) => {
  const { recaptchaEnabled, recaptchaWebSiteKey } = props
  const [ready, setReady] = useState(false)
  const readyHandler = () => {
    setReady(true)
  }
  return (
    <GoogleRecaptchaProvider
      reCaptchaKey={recaptchaWebSiteKey}
      useEnterprise={true}
      disabled={!recaptchaEnabled}
      readyCallBack={readyHandler}
      container={{ element: 'g-recaptcha', parameters: { size: 'normal', action: 'LOGIN' } }}
    >
      <SignupScene captchaReady={ready} {...props} />
    </GoogleRecaptchaProvider>
  )
}

signupSceneWithRecaptchaProvider.propTypes = {
  recaptchaWebSiteKey: PropTypes.string,
  recaptchaEnabled: PropTypes.bool,
}

export default connector(signupSceneWithRecaptchaProvider)
