import { CryphoAPI } from '../../crypho.core/api'

const postToAPI = async (baseUrl, path, payLoad) => {
  const api = new CryphoAPI(baseUrl)
  return await api.post(path, payLoad)
}

const getFromAPI = async (baseUrl, path, payLoad = {}) => {
  const api = new CryphoAPI(baseUrl)
  return await api.get(path, payLoad)
}

const deleteFromAPI = async (baseUrl, path, payLoad = {}) => {
  const api = new CryphoAPI(baseUrl)
  return await api.delete(path, payLoad)
}

const putToAPI = async (baseUrl, path, payLoad = {}) => {
  const api = new CryphoAPI(baseUrl)
  return await api.put(path, payLoad)
}

export const getUser = async (apiUrl, username) => {
  const data = await postToAPI(apiUrl, '/admin', { username })
  const { fullname, email } = data.basic
  return {
    id: username,
    fullName: fullname,
    email,
  }
}

export const getAdmins = async (apiUrl) => {
  let admins = await getFromAPI(apiUrl, '/admin/systemadmins')
  admins = admins.map((admin) => ({
    id: admin.username,
    fullName: admin.fullname,
    email: admin.email,
  }))
  return admins
}

export const addAdmin = async (apiUrl, { email }) => {
  const data = await postToAPI(apiUrl, '/admin/systemadmins', { email })
  return {
    id: data.username,
    fullName: data.fullname,
    email: data.email,
  }
}

export const deleteAdmin = async (apiUrl, id) => {
  await deleteFromAPI(apiUrl, `/admin/systemadmins/${id}`)
}

export const searchUsers = async (apiUrl, searchTerm) => {
  if (searchTerm.length < 3) {
    return []
  }
  const data = await postToAPI(apiUrl, '/admin', { searchTerm })
  return data.map((item) => ({
    value: item.username,
    label: `${item.fullname} ${item.email} ${item.username}`,
  }))
}

export const deleteUser = async (apiUrl, id) => {
  await deleteFromAPI(apiUrl, `/admin/users/${id}`)
}

export const updateEmails = async (apiUrl, id, emails) => {
  await putToAPI(apiUrl, `/admin/users/${id}`, { emails })
}

export const updatePrimaryEmail = async (apiUrl, id, primaryEmail) => {
  const result = await putToAPI(apiUrl, `/admin/users/${id}`, { email: primaryEmail })
  return result
}

export const getPlans = async (apiUrl) => {
  return await getFromAPI(apiUrl, `/admin/plans`)
}

export const updatePlan = async (apiUrl, id, plan) => {
  return await putToAPI(apiUrl, `/admin/users/${id}`, { plan: plan })
}

export const addPlan = async (apiUrl, plan) => {
  return await postToAPI(apiUrl, `/admin/plans`, plan)
}

export const addAccountAdmin = async (apiUrl, email) => {
  return await postToAPI(apiUrl, `/admin/accounts/admins`, email)
}

export const addAccountDomain = async (apiUrl, accountId, domainName) => {
  return await postToAPI(apiUrl, `/admin/accounts/domain`, { account_id: accountId, domain: domainName })
}

export const removeSpace = async (apiUrl, spaceId) => {
  return await deleteFromAPI(apiUrl, `/admin/spaces//${spaceId}`)
}

export const sendSMS = async (apiUrl, sms) => {
  return await postToAPI(apiUrl, '/admin/sms', sms)
}

export const sendEmail = async (apiUrl, email) => {
  return await postToAPI(apiUrl, '/admin/emails', email)
}

export const sendAnnouncement = async (apiUrl, announcement) => {
  return await postToAPI(apiUrl, '/admin/announcements', announcement)
}

export const setAccountIsActive = async (apiUrl, accountId, isActive) => {
  return await postToAPI(apiUrl, `/admin/accounts/is-active`, { account_id: accountId, is_active: isActive })
}

export const getAdminConfig = async (apiUrl) => {
  return await getFromAPI(apiUrl, `/admin/config`)
}

export const setAdminConfig = async (apiUrl, config) => {
  return await postToAPI(apiUrl, `/admin/config`, config)
}
